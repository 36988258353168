import { TaxiController } from "./taxi_controller";

export default class extends TaxiController {
  static targets = ["digit_input"]

  initialize() {
    console.log("VerificationsController")
  }

  connect() {
    this._keyUpDigitInputNext();
  }

  // Private

  _keyUpDigitInputNext() {
    if (this.hasDigit_inputTarget) {
      this.digit_inputTargets.forEach((input, index) => {
        input.addEventListener("keyup", (e) => {
          if (e.target.value.length === 1) {
            if (index === this.digit_inputTargets.length - 1) {
              e.target.form.submit();
            } else {
              const next = this.digit_inputTargets[index + 1];
              next.focus();
            }
          }
        });
      });
    }
  }
}
