import { TaxiController } from "./taxi_controller";
import intlTelInput from 'intl-tel-input';

export default class extends TaxiController {
  static targets = ["phone"]

  initialize() {

    console.log("SignUpController")
  }

  connect() {
    // phone
    if (this.hasPhoneTarget) {
      this._initPhone();
    }
  }

  // Private

  _initPhone() {
    let phone_input = this.phoneTarget;
    let format_input = intlTelInput(phone_input, {
      // allowDropdown: false,
      // autoHideDialCode: false,
      autoPlaceholder: true,
      // dropdownContainer: document.body,
      // excludeCountries: ["us"],
      formatOnDisplay: true,
      // hiddenInput: "full_number",
      initialCountry: "auto",
      localizedCountries: { 'ru': 'Russia' },
      nationalMode: false,
      // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'],
      preferredCountries: ["uz"],
      placeholderNumberType: "MOBILE",
      // preferredCountries: COUNTRIES,
      separateDialCode: false,
      utilsScript: "/js/utils.js"
    });

    phone_input.addEventListener("keyup", function () {
      if (typeof intlTelInputUtils !== 'undefined') {
        const currentText = format_input.getNumber(intlTelInputUtils.numberFormat.E164);
        if (typeof currentText === 'string') {
          format_input.setNumber(currentText);
        }
      }
    });

    phone_input.addEventListener("change", function () {
      if (typeof intlTelInputUtils !== 'undefined') {
        const currentText = format_input.getNumber(intlTelInputUtils.numberFormat.E164);
        if (typeof currentText === 'string') {
          format_input.setNumber(currentText);
        }
      }
    });

    phone_input.addEventListener("focusout", function () {
      if (!this.value) {
        this.value = "+";
      }
    });
  }
}
