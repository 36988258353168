import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Rails from "@rails/ujs"

const application = Application.start()
const context = require.context("./", true, /_controller\.js$/)

application.load(definitionsFromContext(context))

console.log("--------------------12")
Rails.start()
window.Rails = Rails;
