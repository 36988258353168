require("turbolinks").start()
require("jquery")

import '@fortawesome/fontawesome-free/css/all.css';

import '../taxi/js/bootstrap.bundle.min.js';
import '../taxi/js/flag.js';
import '../taxi/js/custom.js';

import "../taxi/controllers";
const images = require.context("../taxi/images", true)
console.log("--------------------14")
const importAll = (r) => r.keys().map(r)
importAll(require.context('../taxi/images', false, /\.(png|jpe?g|svg)$/));